/* theme customize Override css======> */

:root{
  --primary:#78A300!important;
  --lightPrimary:#78A3001a!important;
  --secondary:#79a402a8!important;
  --accent: #6a91000f!important;
  --hover:#9bd203be!important;
  --text: #72849a!important;
  --active-list:#455560!important;
  --danger-alt:#ff6b72!important;
}
.bg-primary{background-color: var(--primary);}
.text-primary{color: var(--primary)!important;}a.text-primary:focus,a.text-primary:hover{color: var(--primary)!important;}
.bg-custom-1{background-color: #eaf4fe!important;transition: all 0.30s ease;}
.bg-primary{background-color: var(--primary)!important}a.bg-primary:focus,a.bg-primary:hover,button.bg-primary:focus,button.bg-primary:hover{background-color:#618402!important}
.ant-menu-light .ant-menu-item-selected{color: var(--primary)!important;}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{color: var(--primary)!important;}
.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected), .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){color: var(--primary)!important;}
.ant-btn-primary{background-color: var(--primary);}
.ant-btn-primary.ant-btn-dangerous {
  background-color: var(--danger-alt);
  box-shadow: 0 2px 0 rgba(255, 22, 5, 0.06);
}
.ant-btn-primary.ant-btn-dangerous:hover{background-color: transparent!important;border: 1px solid var(--danger-alt);}
.ant-ribbon{background-color: var(--primary)!important;}
.ant-menu-light .ant-menu-item-selected{background-color: var(--lightPrimary);}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after{border-color: var(--primary);}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover{background-color: #79a402!important;}
.ant-btn-primary:not(:disabled):hover{background-color: var(--secondary)!important;}
.ant-btn-primary.ant-btn-dangerous:not(:disabled):hover {
  background-color: transparent!important;
  color: var(--danger-alt);
}
.ant-btn-primary.activeBtn:hover,
.ant-btn-primary.inActiveBtn:hover
{background-color: transparent!important;}
.ant-btn-default:not(:disabled):hover{color: var(--primary)!important;border-color: var(--primary)!important;}
.ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus, .ant-input-number-focused{border-color: var(--primary)!important;}
.text-editor .ql-container:hover,
.text-editor .ql-container:focus-within{border:1px solid var(--primary)!important;border-radius: 0 0 10px 10px;}
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke{stroke: var(--primary)!important;}
.ql-toolbar.ql-snow .ql-picker-label:hover{color: var(--primary)!important;}
.ql-snow.ql-toolbar button:hover > *{stroke: var(--primary)!important;}
.ant-spin .ant-spin-dot-item{background-color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-today-btn{color: var(--primary);}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover{color: var(--hover);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border-color: var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{background-color: var(--primary);}
.more-btn{background-color: transparent;color: var(--primary);border: 1px solid var(--primary);}
.more-btn:hover{border-color: transparent;}

/* ========================> */

/* --------Global Css========> */
.bg-cancel{background-color: var(--danger-alt)!important;}
ul{padding: 0;}
ul li{list-style-type: none;}

/* new class css */
.gap-1{gap: 4px;}
.gap-2{gap: 8px;}
.gap-3{gap: 12px;}
.gap-4{gap: 16px;}
.gap-5{gap: 20px;}
.justify-content-evenly{justify-content: space-evenly;}
.cursor-pointer{cursor: pointer;}

/* checkbox */

.check_box {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt {border: 2px solid #e6ebf1;width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt:hover {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt.active{background-color: var(--primary);border-color: var(--primary);}


/* ==============> */
.left-0{left: 0;}
.top-0{top: 0;}
.shadow{box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05)!important;}
.shadow-sm{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.shadow-md{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.show{display: flex;}
.hide{visibility: hidden!important;opacity:0;transition:all 0.30s ease;}
.disabled-btn{cursor: not-allowed;border-color: #e6ebf1;color: rgba(69, 85, 96, 0.25);background-color: rgba(69, 85, 96, 0.04);box-shadow: none;}
.scrollbar_smooth{overflow-y: scroll;overflow-x: hidden;}
.scrollbar_smooth::-webkit-scrollbar{width: 6px;background-color: transparent;}
.scrollbar_smooth::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.scrollbar_smooth::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}
/* form sec */

/* .form-sec .ant-form-item-control > div:nth-child(2){
    position: absolute;
    bottom: -22px
} */
.no_data_box{border-radius: 10px;position: relative;}
.no_data_box div{color: rgba(128, 128, 128, 0.536);}
.loading{width: 100%;height: 100%;position: absolute;backdrop-filter: blur(1px);top:0;left: 0;}
/* ---------------------- */

.login-logo{height: 80px;overflow: hidden;width: 80px;}
.site_logo{object-fit: contain;}

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {border: 1px dashed var(--primary); height: 150px;cursor: pointer;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right{width: 50px;height: 50px;display: flex;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right{filter: grayscale(1);opacity: 0.6;}

.file_upload_sec .img_icon_left{right: -50px;transition: all 0.30s ease;}
.file_upload_sec .img_icon_right{left: -50px;transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_left{right: -20px;transform: rotate(-20deg);transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_right{left: -20px;transform: rotate(20deg);transition: all 0.30s ease;}

.file_upload_sec .img_icon{z-index: 1;}
.file_upload_sec .img_icon img{width: 100%;height: 100%;object-fit: contain; object-position: center;}
.file_upload_sec .uploaded_img{object-fit: cover;object-position: center;}

.file_upload_input{width: 100%;height: 100%;opacity: 0;z-index: 2;cursor: pointer;position: absolute;}
.img_delete_sec{top:0;left: 0;background-color: rgba(174, 173, 173, 0.41);}
.delete_btn{border: 1px solid #ff6b72;display: flex;align-items: center;justify-content: center;width: 40px; height: 40px;border-radius: 50%;background-color: #ff6b72;transition:all 0.30s ease;}
.delete_btn:hover{scale: 1.2;transition:all 0.30s ease;}
/* =====================> */


.tasks_card{background-color: #fafafb;border-radius: 5px;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);position: relative;}
.tasks_card .img_sec{width: 34%;overflow: hidden;border-radius: 5px;}
.tasks_card .list_sec{width: -webkit-fill-available;}
.tasks_card .img_sec img{object-fit: cover;object-position: center;}
.tasks_card .challenge_card > div{height: 136px;}
.tasks_card .challenge_card .list_sec{overflow-y: scroll;}
.tasks_card .list_sec.scrollbar_smooth::-webkit-scrollbar-thumb{background-color:rgba(200, 198, 198, 0.652)!important;}
/* .tasks_card::before{content: '';width: 80px;height: 80px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-three);}
.tasks_card::after{content: '';width: 90px;height: 90px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-two);z-index: 1;} */
.task-sec .task-body > div{
  margin-right: 20px
}
.tasks .ant-card-body{padding-right: 0;}
.tasks_card .list-group-item{background-color: #fff;transition: all 0.30s ease;}
.tasks_card .list-group-item:hover{box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;background:transparent;transition: all 0.30s ease;}
.tasks_card .checkbox_sec{display: flex;justify-content: center;align-items: center;background-color: #fff;border: 0.125rem solid rgba(0,0,0,.25);position: relative;width: 1.125rem;height: 1.125rem;border-radius: 3px;}
.tasks_card .checkbox_sec.checked{background-color: var(--primary);border: 0.125rem solid var(--primary);color: #fff;}
.tasks_card .challenge_card_heading{color: var(--primary);}
.tasks_card .action .btn{width: 25px;height: 25px;background-color: #fafafb;border-radius: 5px;transition:all 0.30s ease}
.tasks_card .checkbox_sec > span.anticon{font-size: 10px;}
.tasks_card .list-group-item:hover .action .btn {background-color: #fff;box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);transition:all 0.30s ease}
.tasks_card .list-group-item:hover .action .btn:hover{scale: 1.2;transition: all 0.30s ease;}
.tasks_card .list-group-item .action .btn.del_btn{color: var(--danger-alt);}
.tasks_card .details{width: 165px;overflow: hidden;}

/* <---------media box------> */
.media_box{border-radius: 4px;cursor: pointer;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);}
.media_box img{object-fit:cover;object-position: center;}
.media_box.pdf_sec .icon img{object-fit: contain;object-position: center;}
.media_box .name_sec{background-color: var(--primary);color: white;overflow: hidden;white-space: nowrap;}
.media_box .name{overflow: hidden;}

/* select custom components */

.select_custom{position: relative;}
.select_custom .list_sec{position:absolute;width: 100%;background-color: white; border-radius: 8px;margin-top: 5px;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);z-index: 2;max-height: 240px;overflow-y: scroll;}
.select_custom .list_sec::-webkit-scrollbar{width: 8px;}
.select_custom .list_sec::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.select_custom .list{cursor: pointer; background-color: transparent;margin: 4px 0;}
.select_custom .list:hover{background-color: #fafafb;}
.select_custom .checked_icon{width: 20px;height: 20px;margin-right: 5px;}
.select_custom .dropdown .selected{background-color: #95b7ff13;}

/* ----delete popup---- */

.delete_popup{background-color: rgba(0, 0, 0, 0.255);z-index: 1000;backdrop-filter: blur(2px)}
.delete_popup .popup{border-radius: 5px;width: 300px;}
.delete_popup .popup .dialogue{line-height: 20px;}
.delete_popup .popup .del_btn{background-color: var(--danger-alt);}
.delete_popup .popup .del_btn:focus-visible{outline: 4px solid #ff848ab1;}
.delete_popup .popup .del_btn:hover{border: 1px solid #ff757c!important;color: var(--danger-alt)!important;background-color: transparent;}
.delete_popup .popup .del_btn:hover span{color: var(--danger-alt)!important;}
/* full screen loading-----> */
.full_screen_loading{z-index: 1000;backdrop-filter: blur(2px);}

/* coach_profile_userlist */

.coach_profile_userlist{height: 325px;}

/* media library */

.media_container {display: flex;gap: 8px;flex-wrap: wrap;}

.card-box {padding: 20px;border-radius: 3px;margin-bottom: 30px;background-color: #fff;}

.file-man-box {overflow:hidden;padding: 10px;border: 1px solid #e3eaef;border-radius: 5px;position: relative;height:144px;width: 144px!important;display: flex;flex-direction: column;justify-content: space-between;cursor: pointer;}
.file-man-box .file-img-box {text-align: center;}
.file-man-box .file-img-box img {height: 64px}

.file-man-box:hover {-webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02)}

.text-overflow {text-overflow: ellipsis;white-space: nowrap;display: block;width: 100%;overflow: hidden;}
.file-man-box h5 {font-size: 13px;margin-bottom: -8px;}
.file-man-box.affirmation{padding: 0;overflow: hidden;}
.file-man-box.affirmation .file-img-box,
.file-man-box.affirmation .file-img-box img{width: 100%;height: 100%;}
.file-man-box.affirmation .file-img-box img{object-fit: cover;object-position: center;}


.file-man-box .action{background-color: #00000070;opacity: 0;visibility: hidden;transition: all 0.30s ease;}
.file-man-box:hover .action{opacity: 1;visibility: visible;transition: all 0.30s ease;}

.file-man-box .action >div{height: 35px; width: 35px;background-color: #fafafb;border-radius: 3px;}
.file-man-box .action >div.del{background-color: var(--danger-alt);color: white;}
.file-man-box .action >div:hover{transform: scale(1.1);transition:all 0.30s ease;}
.file-man-box .check_box{border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px; position: absolute;top: 3px;right: 3px;background-color: white;}
.media_header .ant-upload-list{display: none;}
.uploading_box{border: 1px dashed rgba(128, 128, 128, 0.585);}
.uploading_text{color: gray;font-size: 12px;}

/* view media */
.media_drawer .ant-drawer-body{padding: 12px!important;}
.media_draw_box .media_draw_box_preview{width: 100%;overflow: hidden;height: 150px;border-radius: 5px;}
.media_draw_box .media_draw_box_preview img{object-fit: cover; object-position: center;}
.media_draw_box .details_sec table div{padding: 3px 0;color: gray;}
.media_draw_box .details_sec table tr td:last-child div{padding-left: 30px;color: var(--primary);}

.media_header_sec .check_box{border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;background-color: white;}
.media_header_sec .ant-btn:hover{color: white!important;background-color: #638703c2!important;}
/* library-form */

.library-form .media-sec{
    border: 1px dashed rgba(128, 128, 128, 0.377);
    border-radius: 5px;
    height: 300px;
}

.select-media-sec{max-height: 100%;width: 100%;}

/* quil toolbar */
.ql-toolbar{border-radius: 10px 10px 0 0;}
.quill > div{border-radius: 0 0 10px 10px;height:200px;max-height: 250px;}

/* challenge_list */
/* 
.challenge_list{background-color: white;border-radius: 8px; height: 170px;}
.challenge_list_alt{border-radius: 5px;min-height:215px; max-height: 350px;margin-top: 10px;}
.challenge_list_alt .no_data_box{height: 215px!important;}
.challenge_list_item{background-color: #fafafb; margin-bottom: 10px;border-radius: 5px;padding: 10px;}
.input{outline: 0;border: 1px solid #e6ebf1;height: 40px;padding: 8px 11px;border-radius: 10px;}
.input::placeholder{color: #e6ebf1;}
span.close_btn{background-color: var(--danger-alt);width: 25px;height: 25px;border-radius: 3px;}
span.close_btn:hover{scale: 1.1;transition: all 0.30s ease;}
.challenge_list_item_title{background-color: #fafafb; border-radius: 5px;} */

/* todo_card */

.todo_card{border-radius: 8px;max-height:150px;}
.todo_card .action_btn{position: absolute;top: 10px;right: 10px;}
.action-item{font-size: 12px;}
.todo_card .title_sec{height: 30px;}
.todo_card .description-sec{max-height: 105px;}
.task_sec{max-height: 450px;}

/* avatar_preview */
.avatar_preview_outer{width: 105px;height: 105px;border: 1px solid #72849a4a;border-radius: 50%;padding: 3px;}
.avatar_preview{width:100%;height:100%;border-radius: 50%;overflow: hidden;}
.avatar_preview > div{transform: scale(1.05);}
.avatar_preview img{object-fit: cover;object-position: center;}
.single_upload{position: absolute;width: 100%;height: 100%;}
.single_upload .upload_btn {width: 100%;height: 100%;display: block;border-radius: 50%;}
.single_upload .cng-btn{position: absolute;bottom: 0;right: 10px;background-color: white;width: 25px; height: 25px;border-radius: 50%;}
.single_upload .ant-upload-list.ant-upload-list-text{display: none;}
.profile_overView .ant-progress-inner{height: 3px;}
.profile_overView .progress_sec{width: 100px;}
.profile_overView .overview .role,
.profile_overView .overview .email{color: #808080a1;font-size: 14px;font-weight: 400;}
.profile_overView .overview .email{margin-top: -3px;font-size: 12px;}
.profile_show .label{color: #808080a1;}
.cancel-btn{border: 1px solid var(--primary);}

.task-sec .task-body{max-height: 322px!important;border-radius: 10px;}
.task-sec .task-body{overflow-y: scroll;}
.task-sec .task-body::-webkit-scrollbar{width: 6px;background-color: transparent;}
.task-sec .task-body::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.task-sec .task-body::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}


.task-card-list{background-color: white;padding: 2px 10px;border-radius: 8px;margin-bottom: 5px;}
.noimg_user_logo{width: 39px;height: 39px;margin-right:10px}
.ant-picker-cell{position: relative;}
.ant-picker-cell .alert_dot::after{content: "";position: absolute;top: -7px;width: 6px;height: 6px;max-width: 6px;max-height: 6px;background-color: var(--primary);border-radius: 50%;}
.ant-picker-cell .alart_dot_green::after{content: "";position: absolute;top: -7px;width: 6px;height: 6px;max-width: 6px;max-height: 6px;background-color: var(--danger-alt);border-radius: 50%;}
.table-filter-list{padding: 7px 20px;border-radius: 10px;}
.table-filter-list:hover{background-color: #f0f7ff;}
.filter_list_sec .filter_list{max-height: 165px;overflow-y: scroll;border-bottom: 1px solid rgba(128, 128, 128, 0.311);}
.filter_list_sec .filter_list::-webkit-scrollbar{width: 6px;background-color: transparent;}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.filter_list_sec .filter_list::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}
.marker .dot{width:8px;height: 8px;border-radius: 50%;}
.marker .dot.primary{background-color: var(--primary);}
.marker .dot.success{background-color: var(--danger-alt)}

/* Active Inactive button */

.activeBtn{color: #08979c;background: #e6fffb;border-color: #87e8de;}
.activeBtn:hover{color: #08979c !important;background: #e6fffb !important;border-color: #87e8de !important;}
.inActiveBtn{color: #d4380d;background: #fff2e8 ;border-color: #ffbb96 ;}
.inActiveBtn:hover{background: #fff2e8 !important; color: #d4380d !important;}

/* player */
.audio-player{background-color: white; border-radius: 10px;width: 350px;}
.skip_prev{transform: rotate(90deg);}
.skip_next{transform: rotate(-90deg);}
.skip_prev::before,
.skip_next::before{content: "10";width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;left: 0;font-size: 8px;}
.skip_prev::before{transform: rotate(-90deg);}
.skip_next::before{transform: rotate(90deg);}
.skip_prev,
.skip_next,
.play_pause,
.next,
.prev{width: 50px;height: 50px;font-size: 25px;position: relative;background-color: #f3f5f9;border-radius: 50%;cursor:pointer;}
.skip_prev,
.skip_next{width: 35px;height: 35px;font-size: 20px;}
.progress_bar .ant-progress-outer *{height: 4px;}
.progress_bar_sec{width: 100%;height: 15px;}
.progress_bar_sec > span{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
.progress_bar_sec span.progress{top: 3px;opacity: 0;}
.progress_bar .ant-progress-success-bg{height: 4px!important;cursor: pointer;}
.progress_group{height: 12px;}
.duration_left,
.total_duration{font-size: 10px;}
.audio_name{background-color: #eaf4fe;border-radius: 5px;}
.audio_name .name{width: 235px;overflow: hidden;}
.audio_name .name > div{white-space: nowrap;font-weight: 600;font-size: 17px;}
.audio_list .icon{width: 30px;height: 30px;overflow: hidden;border-radius: 5px;background-color: white;padding: 5px;display: flex;align-items: center;justify-content: center;}
.audio_list .audio_item{border-radius: 5px;padding: 10px 15px;margin-bottom: 5px;}
.audio_list .audio_item.active{background-color: white!important;}
.audio_list .audio_item .name{white-space: nowrap;width: 180px;overflow: hidden;text-overflow: ellipsis;}
.audio_list_sec{max-height: 230px;}
.audio_list_sec .audio_item:hover .name > div{-moz-animation: marquee 15s linear infinite; -webkit-animation: marquee 15s linear infinite; animation: marquee 15s linear infinite;}
.timer_duration{margin-top: 5px;}


/* badge===========> */

.badges_sec .badges .item{width: 50px;height: 50px;overflow: hidden;}
.badges_sec .badges .item:not(:first-child){margin-left: -12px;}
.badges_sec .badges .more_btn{font-size:14px;border-radius:50%;background-color: #fff ;width: 50px;height: 50px;right: 0;cursor: pointer;top: 0;color: var(--primary);transition: all 0.30s ease;}
.badges_sec .badges .more_btn:hover{background-color: var(--primary);color: white;transition: all 0.30s ease;}
.badge_modal .ant-modal-content{background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(24, 179, 148, 1) 100%);}
.badge_modal .ant-modal-header{background-color: transparent;}
.badge_modal .ant-modal-close-x .anticon,
.badge_modal .ant-modal-header .title{color: white;}
.badge_modal .badges{background-image: url(./assets/medel/SPARKLES_GREEN.png);width: 100px;height: 100px;background-size: cover;background-repeat: no-repeat;}
.badge_modal .badges img{width: 76%;height: 76%;margin-top: 20px;margin-left: 10px;}

.coach_profile_userlist.user_sec{max-height: 325px;height: fit-content;}
.badge{background-color: var(--primary);color:white;display: flex;align-items: center;justify-content: center;border-radius: 50%;width: 25px;height: 25px;}

/* chat ai chat gpt section========> */

.chat_list_sec{border-radius: 10px;background-color: var(--accent);margin-bottom: 10px;transition:all 0.30s ease;}
.chat_list_sec:hover{background-color: #6a91002b;transition:all 0.30s ease;}
.chat_list_sec .icon_avatar{overflow: hidden;width: 40px;height: 40px;min-width: 40px;min-height: 40px;border-radius: 30%;}
.chat_list_sec .content_sec{width: 90%;}
.chat_list_sec.active .content_sec{font-weight: 500;}
.chat_list_sec .content{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.chat_list_sec .date{font-size: 13px;color: var(--text);}
.new_chat_btn button{width: 100%;border: 1px solid transparent;background-color: var(--primary);color: white;transition:all 0.30s ease;}
.new_chat_btn button:hover{background-color: transparent;border-color: var(--primary);color: var(--primary);transition:all 0.30s ease;}
.chat_box_sec{border-radius:10px;height: 420px; max-height: 500px;background-image: url(./assets/img/bot-bg.jpg);background-repeat: no-repeat;background-size: cover; background-position: center;}
.chat_box_sec .box{backdrop-filter: blur(1px);border-radius: 10px;}
.chat_box{overflow: initial;height:100%;}
.chat_form button.send_chat{padding: 0;width: 50px;height: 50px;}
.chat_box_sec .chat .avatar{overflow: hidden;width:40px;height: 40px;min-width:40px;min-height: 40px;border-radius: 30%;}
.chat_box_sec .chat .content{background-color: #60800ad2;color: white;}
.chat_box_sec .left_chat .content{border-radius: 8px 8px 8px 0;width:fit-content;max-width: 70%;}
.chat_box_sec .right_chat .content{border-radius: 8px 8px 0 8px;width:fit-content;max-width: 70%;}
.blinking-cursor{width: 6px;height: 14px;background-color: white;animation: blink 1s infinite;}
@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.1%, 100% {
    opacity: 0;
  }
}
.chat_list_container{max-height: 497px;}
.ai-form-container{border-radius: 10px;background-color: var(--accent);}
.active_point{width: 10px;height: 10px;border-radius: 50%;background-color: var(--primary);}

/* social_media_add_sec=======> */

.social_media_sec{max-height: 465px;border-radius: 10px;}
.social_media_add_sec .social_media_add{background-color: var(--accent);border-radius: 10px;}


















































.marquee_animation {
    -moz-animation: marquee 15s linear infinite;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
  }

  @-moz-keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }


  @-webkit-keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee {
    0% {
      -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      transform: translateX(0%)
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  .custom-button {
    background: white;
    border-color: #3b30eb;
    color: #3b30eb;
  }

  .bullet{list-style-type: disc; margin-left: 20px}

